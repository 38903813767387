<template>
  <!-- Page Content  -->
  <b-container fluid>
    <!-- BEGIN:Modal Create -->
    <b-modal
      id="modal-create"
      v-model="isModalCreate"
      size="lg"
      centered
      title="Tambah Data Group Mitra"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group
          label="Nama Group Mitra:"
          label-for="namaGroup"
          label-class="text-dark"
        >
          <input-primary
            id="namaGroup"
            v-model="form.namaGroup"
            required
            max-length="100"
          />
        </b-form-group>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalCreate">Batal</b-btn>
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="createData"
              :disabled="!isValidForm"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Create -->
    <!-- BEGIN:Modal Edit -->
    <b-modal
      id="modal-edit"
      v-model="isModalEdit"
      size="lg"
      centered
      title="Ubah Data Group Mitra"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group
          label="Kode Group Mitra:"
          label-for="kodeGroup"
          label-class="text-dark"
        >
          <input-primary
            id="kodeGroup"
            v-model="modal.kodeGroup"
            required
            isDetail
            type="number"
            max-length="30"
          />
        </b-form-group>
        <b-form-group
          label="Nama Group Mitra:"
          label-for="namaGroup"
          label-class="text-dark"
        >
          <input-primary
            id="namaGroup"
            v-model="modal.namaGroup"
            required
            max-length="100"
          />
        </b-form-group>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalEdit">Batal</b-btn>
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="editData"
              :disabled="!isValidModal"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Edit -->
    <!-- BEGIN:Modal Delete -->
    <b-modal
      id="modal-delete"
      v-model="isModalDelete"
      centered
      title="Hapus Data Group Mitra"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="danger"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <h5 class="text-center">
          Apakah anda yakin ingin menghapus data<br /><b
            >{{ modal.kodeGroup }} | {{ modal.namaGroup }}</b
          >?
        </h5>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="isModalDelete = false"
              >Batal</b-btn
            >
          </b-col>
          <b-col>
            <b-btn class="btn-block" variant="success" @click="deleteData"
              >Hapus</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Delete -->
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Daftar Group Mitra</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-50"
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <div class="d-inline-block float-right">
                    <b-button @click="showModalCreate" variant="primary"
                      >Tambah Data<i class="ri-add-line ml-2"></i
                    ></b-button>
                  </div>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="thead"
                :items="listData"
                class="mt-2"
                :busy="isLoading"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
                show-empty
              >
                <template v-slot:cell(aksi)="listData">
                  <b-button
                    class="mx-1"
                    variant=" iq-bg-success"
                    size="sm"
                    @click="
                      showModalEdit(
                        listData.item.idGroup,
                        listData.item.kodeGroup,
                        listData.item.namaGroup
                      )
                    "
                    ><i class="ri-ball-pen-fill m-0"></i>Ubah</b-button
                  >
                  <b-button
                    class="mx-1"
                    variant=" iq-bg-danger"
                    size="sm"
                    @click="
                      showModalDelete(
                        listData.item.idGroup,
                        listData.item.kodeGroup,
                        listData.item.namaGroup
                      )
                    "
                    ><i class="ri-delete-bin-line m-0"></i>Hapus</b-button
                  >
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div class="text-danger font-weight-bold mx-auto text-center">
                    Data tidak ditemukan
                  </div>
                </template>
                <template #emptyfiltered>
                  <div class="text-danger font-weight-bold mx-auto text-center">
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
// import { core } from '@/config/pluginInit'
import axios from 'axios'
import { mapState } from 'vuex'
import { Vue } from 'vue-property-decorator'
import store from '@/store/index'
import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'master.groupMitra',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status = this.form.namaGroup !== null
      return status
    },
    isValidModal() {
      const status = this.modal.namaGroup !== null
      return status
    }
  },
  mounted() {
    /** Innit data */
    this.innitData()
  },
  methods: {
    /**
     * Get data group mitra
     */
    async getDataGroupMitra_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListMasterPks?type=groupMitra`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listData = res.data
          /** Set the initial number of items */
          this.totalRows = this.listData.length
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    /**
     * Reset Value
     */
    resetForm() {
      this.form.namaGroup = null
    },
    resetModal() {
      this.modal.namaGroup = null
    },
    /**
     * Create Group Mitra
     */
    showModalCreate() {
      this.resetForm()
      this.isModalCreate = true
    },
    closeModalCreate() {
      this.resetForm()
      this.isModalCreate = false
    },
    async createData() {
      const bodyFormData = new FormData()
      bodyFormData.append('type', this.typeData)
      bodyFormData.append('namaGroup', this.form.namaGroup)

      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/tambahDataMasterPks`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.getDataGroupMitra_()
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          this.isModalCreate = false
          this.$toast.error('Gagal simpan data')
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.isModalCreate = false
      return result
    },
    /**
     * Edit Group Mitra
     */
    showModalEdit(id, kode, nama) {
      this.resetModal()
      this.modal.idGroup = id
      this.modal.kodeGroup = kode
      this.modal.namaGroup = nama
      this.isModalEdit = true
    },
    closeModalEdit() {
      this.resetModal()
      this.isModalEdit = false
    },
    async editData() {
      const bodyFormData = new FormData()
      bodyFormData.append('type', this.typeData)
      bodyFormData.append('idGroup', this.modal.idGroup)
      bodyFormData.append('kodeGroup', this.modal.kodeGroup)
      bodyFormData.append('namaGroup', this.modal.namaGroup)

      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/ubahDataMasterPks`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.getDataGroupMitra_()
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          this.isModalEdit = false
          this.$toast.error('Gagal ubah data')
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.isModalEdit = false
      return result
    },
    /**
     * Delete Group Mitra
     */
    showModalDelete(id, kode, nama) {
      this.modal.idGroup = id
      this.modal.kodeGroup = kode
      this.modal.namaGroup = nama
      this.isModalDelete = true
    },
    async deleteData() {
      const bodyFormData = new URLSearchParams()
      bodyFormData.append('type', this.typeData)
      bodyFormData.append('idGroup', this.modal.idGroup)

      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/hapusDataMasterPks`,
        method: 'DELETE',
        data: bodyFormData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.getDataGroupMitra_()
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          this.isModalDelete = false
          this.$toast.error('Gagal hapus data')
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.isModalDelete = false
      return result
    },
    /**
     * Init Data
     */
    async innitData() {
      /** Get List Data */
      this.isLoading = true
      await Promise.all([this.getDataGroupMitra_()])
      this.isLoading = false
    }
  },
  data() {
    return {
      typeData: 'groupMitra',
      // Tabel Options
      isLoading: false,
      filter: null,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPage: 1,
      totalRows: 1,
      // isModal
      isModalCreate: false,
      isModalEdit: false,
      isModalDelete: false,
      // Form
      form: {
        namaGroup: null
      },
      // Modal
      modal: {
        idGroup: null,
        kodeGroup: null,
        namaGroup: null
      },
      // Table
      thead: [
        {
          label: 'Kode Group Mitra',
          key: 'kodeGroup',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Nama Group Mitra',
          key: 'namaGroup',
          class: 'text-left w-75',
          sortable: true
        },
        { label: 'Aksi', key: 'aksi', class: 'text-center w-25' }
      ],
      listData: []
    }
  },
  beforeRouteEnter(to, from, next) {
    const idUnitKs = store.state.authService.idUnitKs
    if (MENU_ROLE.MASTER_GROUP_MITRA.includes(String(idUnitKs))) {
      return next()
    } else {
      Vue.$toast.error('Anda tidak memiliki hak akses')
      return next({ name: 'sidebar.dashboard' })
    }
  }
}
</script>
